import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AccordionMenu: () => import('../../node_modules/@osp/design-system/components/AccordionMenu/AccordionMenu.vue' /* webpackChunkName: "components/accordion-menu" */).then(c => wrapFunctional(c.default || c)),
  AccordionMenuItem: () => import('../../node_modules/@osp/design-system/components/AccordionMenuItem/AccordionMenuItem.vue' /* webpackChunkName: "components/accordion-menu-item" */).then(c => wrapFunctional(c.default || c)),
  AddressTile: () => import('../../node_modules/@osp/design-system/components/AddressTile/AddressTile.vue' /* webpackChunkName: "components/address-tile" */).then(c => wrapFunctional(c.default || c)),
  AddToCartModal: () => import('../../node_modules/@osp/design-system/components/AddToCartModal/AddToCartModal.vue' /* webpackChunkName: "components/add-to-cart-modal" */).then(c => wrapFunctional(c.default || c)),
  AppliedFilters: () => import('../../node_modules/@osp/design-system/components/AppliedFilters/AppliedFilters.vue' /* webpackChunkName: "components/applied-filters" */).then(c => wrapFunctional(c.default || c)),
  ArProductViewButton: () => import('../../node_modules/@osp/design-system/components/ArProductViewButton/ArProductViewButton.vue' /* webpackChunkName: "components/ar-product-view-button" */).then(c => wrapFunctional(c.default || c)),
  BonusTip: () => import('../../node_modules/@osp/design-system/components/BonusTip/BonusTip.vue' /* webpackChunkName: "components/bonus-tip" */).then(c => wrapFunctional(c.default || c)),
  BrandList: () => import('../../node_modules/@osp/design-system/components/BrandList/BrandList.vue' /* webpackChunkName: "components/brand-list" */).then(c => wrapFunctional(c.default || c)),
  BrandLogoTile: () => import('../../node_modules/@osp/design-system/components/BrandLogoTile/BrandLogoTile.vue' /* webpackChunkName: "components/brand-logo-tile" */).then(c => wrapFunctional(c.default || c)),
  Breadcrumb: () => import('../../node_modules/@osp/design-system/components/Breadcrumb/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  BurgerList: () => import('../../node_modules/@osp/design-system/components/BurgerList/BurgerList.vue' /* webpackChunkName: "components/burger-list" */).then(c => wrapFunctional(c.default || c)),
  CallCenterCta: () => import('../../node_modules/@osp/design-system/components/CallCenterCta/CallCenterCta.vue' /* webpackChunkName: "components/call-center-cta" */).then(c => wrapFunctional(c.default || c)),
  CalloutBox: () => import('../../node_modules/@osp/design-system/components/CalloutBox/CalloutBox.vue' /* webpackChunkName: "components/callout-box" */).then(c => wrapFunctional(c.default || c)),
  CampaignTeaser: () => import('../../node_modules/@osp/design-system/components/CampaignTeaser/CampaignTeaser.vue' /* webpackChunkName: "components/campaign-teaser" */).then(c => wrapFunctional(c.default || c)),
  CategoryList: () => import('../../node_modules/@osp/design-system/components/CategoryList/CategoryList.vue' /* webpackChunkName: "components/category-list" */).then(c => wrapFunctional(c.default || c)),
  CategorySlider: () => import('../../node_modules/@osp/design-system/components/CategorySlider/CategorySlider.vue' /* webpackChunkName: "components/category-slider" */).then(c => wrapFunctional(c.default || c)),
  Checkbox: () => import('../../node_modules/@osp/design-system/components/Checkbox/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c)),
  ChooseButton: () => import('../../node_modules/@osp/design-system/components/ChooseButton/ChooseButton.vue' /* webpackChunkName: "components/choose-button" */).then(c => wrapFunctional(c.default || c)),
  ClickAndCollectStoreCard: () => import('../../node_modules/@osp/design-system/components/ClickAndCollectStoreCard/ClickAndCollectStoreCard.vue' /* webpackChunkName: "components/click-and-collect-store-card" */).then(c => wrapFunctional(c.default || c)),
  ClsSection: () => import('../../node_modules/@osp/design-system/components/ClsSection/ClsSection.vue' /* webpackChunkName: "components/cls-section" */).then(c => wrapFunctional(c.default || c)),
  ClubCard: () => import('../../node_modules/@osp/design-system/components/ClubCard/ClubCard.vue' /* webpackChunkName: "components/club-card" */).then(c => wrapFunctional(c.default || c)),
  ClubCardCode: () => import('../../node_modules/@osp/design-system/components/ClubCardCode/ClubCardCode.vue' /* webpackChunkName: "components/club-card-code" */).then(c => wrapFunctional(c.default || c)),
  ClubCta: () => import('../../node_modules/@osp/design-system/components/ClubCta/ClubCta.vue' /* webpackChunkName: "components/club-cta" */).then(c => wrapFunctional(c.default || c)),
  ClubJoin: () => import('../../node_modules/@osp/design-system/components/ClubJoin/ClubJoin.vue' /* webpackChunkName: "components/club-join" */).then(c => wrapFunctional(c.default || c)),
  ColorSwitcher: () => import('../../node_modules/@osp/design-system/components/ColorSwitcher/ColorSwitcher.vue' /* webpackChunkName: "components/color-switcher" */).then(c => wrapFunctional(c.default || c)),
  ContentNavigation: () => import('../../node_modules/@osp/design-system/components/ContentNavigation/ContentNavigation.vue' /* webpackChunkName: "components/content-navigation" */).then(c => wrapFunctional(c.default || c)),
  Countdown: () => import('../../node_modules/@osp/design-system/components/Countdown/Countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c)),
  DesktopListing: () => import('../../node_modules/@osp/design-system/components/DesktopListing/DesktopListing.vue' /* webpackChunkName: "components/desktop-listing" */).then(c => wrapFunctional(c.default || c)),
  FacetFilterDropdown: () => import('../../node_modules/@osp/design-system/components/FacetFilterDropdown/FacetFilterDropdown.vue' /* webpackChunkName: "components/facet-filter-dropdown" */).then(c => wrapFunctional(c.default || c)),
  FacetFilterDropdownList: () => import('../../node_modules/@osp/design-system/components/FacetFilterDropdownList/FacetFilterDropdownList.vue' /* webpackChunkName: "components/facet-filter-dropdown-list" */).then(c => wrapFunctional(c.default || c)),
  FeatureBox: () => import('../../node_modules/@osp/design-system/components/FeatureBox/FeatureBox.vue' /* webpackChunkName: "components/feature-box" */).then(c => wrapFunctional(c.default || c)),
  FeaturesGrid: () => import('../../node_modules/@osp/design-system/components/FeaturesGrid/FeaturesGrid.vue' /* webpackChunkName: "components/features-grid" */).then(c => wrapFunctional(c.default || c)),
  FilterButton: () => import('../../node_modules/@osp/design-system/components/FilterButton/FilterButton.vue' /* webpackChunkName: "components/filter-button" */).then(c => wrapFunctional(c.default || c)),
  FilterButtonMobile: () => import('../../node_modules/@osp/design-system/components/FilterButtonMobile/FilterButtonMobile.vue' /* webpackChunkName: "components/filter-button-mobile" */).then(c => wrapFunctional(c.default || c)),
  FilterControls: () => import('../../node_modules/@osp/design-system/components/FilterControls/FilterControls.vue' /* webpackChunkName: "components/filter-controls" */).then(c => wrapFunctional(c.default || c)),
  FilterListItem: () => import('../../node_modules/@osp/design-system/components/FilterListItem/FilterListItem.vue' /* webpackChunkName: "components/filter-list-item" */).then(c => wrapFunctional(c.default || c)),
  FilterMenu: () => import('../../node_modules/@osp/design-system/components/FilterMenu/FilterMenu.vue' /* webpackChunkName: "components/filter-menu" */).then(c => wrapFunctional(c.default || c)),
  FilterPrice: () => import('../../node_modules/@osp/design-system/components/FilterPrice/FilterPrice.vue' /* webpackChunkName: "components/filter-price" */).then(c => wrapFunctional(c.default || c)),
  FilterTrigger: () => import('../../node_modules/@osp/design-system/components/FilterTrigger/FilterTrigger.vue' /* webpackChunkName: "components/filter-trigger" */).then(c => wrapFunctional(c.default || c)),
  Flyout: () => import('../../node_modules/@osp/design-system/components/Flyout/Flyout.vue' /* webpackChunkName: "components/flyout" */).then(c => wrapFunctional(c.default || c)),
  FlyoutBrands: () => import('../../node_modules/@osp/design-system/components/FlyoutBrands/FlyoutBrands.vue' /* webpackChunkName: "components/flyout-brands" */).then(c => wrapFunctional(c.default || c)),
  FlyoutImages: () => import('../../node_modules/@osp/design-system/components/FlyoutImages/FlyoutImages.vue' /* webpackChunkName: "components/flyout-images" */).then(c => wrapFunctional(c.default || c)),
  FlyoutImagetitle: () => import('../../node_modules/@osp/design-system/components/FlyoutImagetitle/FlyoutImagetitle.vue' /* webpackChunkName: "components/flyout-imagetitle" */).then(c => wrapFunctional(c.default || c)),
  FlyoutList: () => import('../../node_modules/@osp/design-system/components/FlyoutList/FlyoutList.vue' /* webpackChunkName: "components/flyout-list" */).then(c => wrapFunctional(c.default || c)),
  FlyoutListTitle: () => import('../../node_modules/@osp/design-system/components/FlyoutListTitle/FlyoutListTitle.vue' /* webpackChunkName: "components/flyout-list-title" */).then(c => wrapFunctional(c.default || c)),
  FlyoutMasonry: () => import('../../node_modules/@osp/design-system/components/FlyoutMasonry/FlyoutMasonry.vue' /* webpackChunkName: "components/flyout-masonry" */).then(c => wrapFunctional(c.default || c)),
  FooterInfo: () => import('../../node_modules/@osp/design-system/components/FooterInfo/FooterInfo.vue' /* webpackChunkName: "components/footer-info" */).then(c => wrapFunctional(c.default || c)),
  FooterNavigation: () => import('../../node_modules/@osp/design-system/components/FooterNavigation/FooterNavigation.vue' /* webpackChunkName: "components/footer-navigation" */).then(c => wrapFunctional(c.default || c)),
  FooterNewsletter: () => import('../../node_modules/@osp/design-system/components/FooterNewsletter/FooterNewsletter.vue' /* webpackChunkName: "components/footer-newsletter" */).then(c => wrapFunctional(c.default || c)),
  FooterUsp: () => import('../../node_modules/@osp/design-system/components/FooterUsp/FooterUsp.vue' /* webpackChunkName: "components/footer-usp" */).then(c => wrapFunctional(c.default || c)),
  FormField: () => import('../../node_modules/@osp/design-system/components/FormField/FormField.vue' /* webpackChunkName: "components/form-field" */).then(c => wrapFunctional(c.default || c)),
  GenericForm: () => import('../../node_modules/@osp/design-system/components/GenericForm/GenericForm.vue' /* webpackChunkName: "components/generic-form" */).then(c => wrapFunctional(c.default || c)),
  HeaderDesktop: () => import('../../node_modules/@osp/design-system/components/HeaderDesktop/HeaderDesktop.vue' /* webpackChunkName: "components/header-desktop" */).then(c => wrapFunctional(c.default || c)),
  HeaderMobile: () => import('../../node_modules/@osp/design-system/components/HeaderMobile/HeaderMobile.vue' /* webpackChunkName: "components/header-mobile" */).then(c => wrapFunctional(c.default || c)),
  HorizontalNavigation: () => import('../../node_modules/@osp/design-system/components/HorizontalNavigation/HorizontalNavigation.vue' /* webpackChunkName: "components/horizontal-navigation" */).then(c => wrapFunctional(c.default || c)),
  HtmlFragment: () => import('../../node_modules/@osp/design-system/components/HtmlFragment/HtmlFragment.vue' /* webpackChunkName: "components/html-fragment" */).then(c => wrapFunctional(c.default || c)),
  HydrateableClientOnly: () => import('../../node_modules/@osp/design-system/components/HydrateableClientOnly/HydrateableClientOnly.vue' /* webpackChunkName: "components/hydrateable-client-only" */).then(c => wrapFunctional(c.default || c)),
  Ico: () => import('../../node_modules/@osp/design-system/components/Ico/Ico.vue' /* webpackChunkName: "components/ico" */).then(c => wrapFunctional(c.default || c)),
  IconButton: () => import('../../node_modules/@osp/design-system/components/IconButton/IconButton.vue' /* webpackChunkName: "components/icon-button" */).then(c => wrapFunctional(c.default || c)),
  ImageTile: () => import('../../node_modules/@osp/design-system/components/ImageTile/ImageTile.vue' /* webpackChunkName: "components/image-tile" */).then(c => wrapFunctional(c.default || c)),
  ImageTileWithDescription: () => import('../../node_modules/@osp/design-system/components/ImageTileWithDescription/ImageTileWithDescription.vue' /* webpackChunkName: "components/image-tile-with-description" */).then(c => wrapFunctional(c.default || c)),
  InViewportTrigger: () => import('../../node_modules/@osp/design-system/components/InViewportTrigger/InViewportTrigger.vue' /* webpackChunkName: "components/in-viewport-trigger" */).then(c => wrapFunctional(c.default || c)),
  InfoBox: () => import('../../node_modules/@osp/design-system/components/InfoBox/InfoBox.vue' /* webpackChunkName: "components/info-box" */).then(c => wrapFunctional(c.default || c)),
  InlineMessage: () => import('../../node_modules/@osp/design-system/components/InlineMessage/InlineMessage.vue' /* webpackChunkName: "components/inline-message" */).then(c => wrapFunctional(c.default || c)),
  InlineSvg: () => import('../../node_modules/@osp/design-system/components/InlineSvg/InlineSvg.vue' /* webpackChunkName: "components/inline-svg" */).then(c => wrapFunctional(c.default || c)),
  InputList: () => import('../../node_modules/@osp/design-system/components/InputList/InputList.vue' /* webpackChunkName: "components/input-list" */).then(c => wrapFunctional(c.default || c)),
  InstallmentButton: () => import('../../node_modules/@osp/design-system/components/InstallmentButton/InstallmentButton.vue' /* webpackChunkName: "components/installment-button" */).then(c => wrapFunctional(c.default || c)),
  InstallmentModal: () => import('../../node_modules/@osp/design-system/components/InstallmentModal/InstallmentModal.vue' /* webpackChunkName: "components/installment-modal" */).then(c => wrapFunctional(c.default || c)),
  IntersectingComponent: () => import('../../node_modules/@osp/design-system/components/IntersectingComponent/IntersectingComponent.vue' /* webpackChunkName: "components/intersecting-component" */).then(c => wrapFunctional(c.default || c)),
  ItemPrice: () => import('../../node_modules/@osp/design-system/components/ItemPrice/ItemPrice.vue' /* webpackChunkName: "components/item-price" */).then(c => wrapFunctional(c.default || c)),
  JerseyPrintModal: () => import('../../node_modules/@osp/design-system/components/JerseyPrintModal/JerseyPrintModal.vue' /* webpackChunkName: "components/jersey-print-modal" */).then(c => wrapFunctional(c.default || c)),
  KeyboardAvoidingView: () => import('../../node_modules/@osp/design-system/components/KeyboardAvoidingView/KeyboardAvoidingView.vue' /* webpackChunkName: "components/keyboard-avoiding-view" */).then(c => wrapFunctional(c.default || c)),
  LanguageSwitcher: () => import('../../node_modules/@osp/design-system/components/LanguageSwitcher/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c)),
  Link: () => import('../../node_modules/@osp/design-system/components/Link/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c)),
  LinkList: () => import('../../node_modules/@osp/design-system/components/LinkList/LinkList.vue' /* webpackChunkName: "components/link-list" */).then(c => wrapFunctional(c.default || c)),
  List: () => import('../../node_modules/@osp/design-system/components/List/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c)),
  ListingHeadline: () => import('../../node_modules/@osp/design-system/components/ListingHeadline/ListingHeadline.vue' /* webpackChunkName: "components/listing-headline" */).then(c => wrapFunctional(c.default || c)),
  LoginCta: () => import('../../node_modules/@osp/design-system/components/LoginCta/LoginCta.vue' /* webpackChunkName: "components/login-cta" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../node_modules/@osp/design-system/components/Logo/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  Media: () => import('../../node_modules/@osp/design-system/components/Media/Media.vue' /* webpackChunkName: "components/media" */).then(c => wrapFunctional(c.default || c)),
  MiniCart: () => import('../../node_modules/@osp/design-system/components/MiniCart/MiniCart.vue' /* webpackChunkName: "components/mini-cart" */).then(c => wrapFunctional(c.default || c)),
  MobileListing: () => import('../../node_modules/@osp/design-system/components/MobileListing/MobileListing.vue' /* webpackChunkName: "components/mobile-listing" */).then(c => wrapFunctional(c.default || c)),
  Modal: () => import('../../node_modules/@osp/design-system/components/Modal/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c)),
  MyAccountOrderAddresses: () => import('../../node_modules/@osp/design-system/components/MyAccountOrderAddresses/MyAccountOrderAddresses.vue' /* webpackChunkName: "components/my-account-order-addresses" */).then(c => wrapFunctional(c.default || c)),
  MyAccountOrderProgressBar: () => import('../../node_modules/@osp/design-system/components/MyAccountOrderProgressBar/MyAccountOrderProgressBar.vue' /* webpackChunkName: "components/my-account-order-progress-bar" */).then(c => wrapFunctional(c.default || c)),
  MyAccountOrderSummary: () => import('../../node_modules/@osp/design-system/components/MyAccountOrderSummary/MyAccountOrderSummary.vue' /* webpackChunkName: "components/my-account-order-summary" */).then(c => wrapFunctional(c.default || c)),
  MyAccountPurchasedProducts: () => import('../../node_modules/@osp/design-system/components/MyAccountPurchasedProducts/MyAccountPurchasedProducts.vue' /* webpackChunkName: "components/my-account-purchased-products" */).then(c => wrapFunctional(c.default || c)),
  NavigationList: () => import('../../node_modules/@osp/design-system/components/NavigationList/NavigationList.vue' /* webpackChunkName: "components/navigation-list" */).then(c => wrapFunctional(c.default || c)),
  NextDayDeliveryCountdown: () => import('../../node_modules/@osp/design-system/components/NextDayDeliveryCountdown/NextDayDeliveryCountdown.vue' /* webpackChunkName: "components/next-day-delivery-countdown" */).then(c => wrapFunctional(c.default || c)),
  NoResultsCard: () => import('../../node_modules/@osp/design-system/components/NoResultsCard/NoResultsCard.vue' /* webpackChunkName: "components/no-results-card" */).then(c => wrapFunctional(c.default || c)),
  OrderItem: () => import('../../node_modules/@osp/design-system/components/OrderItem/OrderItem.vue' /* webpackChunkName: "components/order-item" */).then(c => wrapFunctional(c.default || c)),
  OrderTile: () => import('../../node_modules/@osp/design-system/components/OrderTile/OrderTile.vue' /* webpackChunkName: "components/order-tile" */).then(c => wrapFunctional(c.default || c)),
  Overlay: () => import('../../node_modules/@osp/design-system/components/Overlay/Overlay.vue' /* webpackChunkName: "components/overlay" */).then(c => wrapFunctional(c.default || c)),
  PaginationNavigation: () => import('../../node_modules/@osp/design-system/components/PaginationNavigation/PaginationNavigation.vue' /* webpackChunkName: "components/pagination-navigation" */).then(c => wrapFunctional(c.default || c)),
  PosAvailabilityCheck: () => import('../../node_modules/@osp/design-system/components/PosAvailabilityCheck/PosAvailabilityCheck.vue' /* webpackChunkName: "components/pos-availability-check" */).then(c => wrapFunctional(c.default || c)),
  Price: () => import('../../node_modules/@osp/design-system/components/Price/Price.vue' /* webpackChunkName: "components/price" */).then(c => wrapFunctional(c.default || c)),
  ProductAvailability: () => import('../../node_modules/@osp/design-system/components/ProductAvailability/ProductAvailability.vue' /* webpackChunkName: "components/product-availability" */).then(c => wrapFunctional(c.default || c)),
  ProductDeliveryTime: () => import('../../node_modules/@osp/design-system/components/ProductDeliveryTime/ProductDeliveryTime.vue' /* webpackChunkName: "components/product-delivery-time" */).then(c => wrapFunctional(c.default || c)),
  ProductDescription: () => import('../../node_modules/@osp/design-system/components/ProductDescription/ProductDescription.vue' /* webpackChunkName: "components/product-description" */).then(c => wrapFunctional(c.default || c)),
  ProductDetails: () => import('../../node_modules/@osp/design-system/components/ProductDetails/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c)),
  ProductImageGrid: () => import('../../node_modules/@osp/design-system/components/ProductImageGrid/ProductImageGrid.vue' /* webpackChunkName: "components/product-image-grid" */).then(c => wrapFunctional(c.default || c)),
  ProductPromotionTags: () => import('../../node_modules/@osp/design-system/components/ProductPromotionTags/ProductPromotionTags.vue' /* webpackChunkName: "components/product-promotion-tags" */).then(c => wrapFunctional(c.default || c)),
  ProductReviews: () => import('../../node_modules/@osp/design-system/components/ProductReviews/ProductReviews.vue' /* webpackChunkName: "components/product-reviews" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewsHeader: () => import('../../node_modules/@osp/design-system/components/ProductReviewsHeader/ProductReviewsHeader.vue' /* webpackChunkName: "components/product-reviews-header" */).then(c => wrapFunctional(c.default || c)),
  ProductSizing: () => import('../../node_modules/@osp/design-system/components/ProductSizing/ProductSizing.vue' /* webpackChunkName: "components/product-sizing" */).then(c => wrapFunctional(c.default || c)),
  ProductTile: () => import('../../node_modules/@osp/design-system/components/ProductTile/ProductTile.vue' /* webpackChunkName: "components/product-tile" */).then(c => wrapFunctional(c.default || c)),
  ProductTitle: () => import('../../node_modules/@osp/design-system/components/ProductTitle/ProductTitle.vue' /* webpackChunkName: "components/product-title" */).then(c => wrapFunctional(c.default || c)),
  PromoBar: () => import('../../node_modules/@osp/design-system/components/PromoBar/PromoBar.vue' /* webpackChunkName: "components/promo-bar" */).then(c => wrapFunctional(c.default || c)),
  PromotionTag: () => import('../../node_modules/@osp/design-system/components/PromotionTag/PromotionTag.vue' /* webpackChunkName: "components/promotion-tag" */).then(c => wrapFunctional(c.default || c)),
  PromotionTeaser: () => import('../../node_modules/@osp/design-system/components/PromotionTeaser/PromotionTeaser.vue' /* webpackChunkName: "components/promotion-teaser" */).then(c => wrapFunctional(c.default || c)),
  Radio: () => import('../../node_modules/@osp/design-system/components/Radio/Radio.vue' /* webpackChunkName: "components/radio" */).then(c => wrapFunctional(c.default || c)),
  RatingLine: () => import('../../node_modules/@osp/design-system/components/RatingLine/RatingLine.vue' /* webpackChunkName: "components/rating-line" */).then(c => wrapFunctional(c.default || c)),
  ReadMore: () => import('../../node_modules/@osp/design-system/components/ReadMore/ReadMore.vue' /* webpackChunkName: "components/read-more" */).then(c => wrapFunctional(c.default || c)),
  RichText: () => import('../../node_modules/@osp/design-system/components/RichText/RichText.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c)),
  ScrollToTopButton: () => import('../../node_modules/@osp/design-system/components/ScrollToTopButton/ScrollToTopButton.vue' /* webpackChunkName: "components/scroll-to-top-button" */).then(c => wrapFunctional(c.default || c)),
  Search: () => import('../../node_modules/@osp/design-system/components/Search/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c)),
  SearchBar: () => import('../../node_modules/@osp/design-system/components/SearchBar/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c)),
  SearchTrigger: () => import('../../node_modules/@osp/design-system/components/SearchTrigger/SearchTrigger.vue' /* webpackChunkName: "components/search-trigger" */).then(c => wrapFunctional(c.default || c)),
  SectionTitle: () => import('../../node_modules/@osp/design-system/components/SectionTitle/SectionTitle.vue' /* webpackChunkName: "components/section-title" */).then(c => wrapFunctional(c.default || c)),
  SelectField: () => import('../../node_modules/@osp/design-system/components/SelectField/SelectField.vue' /* webpackChunkName: "components/select-field" */).then(c => wrapFunctional(c.default || c)),
  SeoText: () => import('../../node_modules/@osp/design-system/components/SeoText/SeoText.vue' /* webpackChunkName: "components/seo-text" */).then(c => wrapFunctional(c.default || c)),
  ShippingAndReturn: () => import('../../node_modules/@osp/design-system/components/ShippingAndReturn/ShippingAndReturn.vue' /* webpackChunkName: "components/shipping-and-return" */).then(c => wrapFunctional(c.default || c)),
  SideNavigation: () => import('../../node_modules/@osp/design-system/components/SideNavigation/SideNavigation.vue' /* webpackChunkName: "components/side-navigation" */).then(c => wrapFunctional(c.default || c)),
  SimpleLinkList: () => import('../../node_modules/@osp/design-system/components/SimpleLinkList/SimpleLinkList.vue' /* webpackChunkName: "components/simple-link-list" */).then(c => wrapFunctional(c.default || c)),
  SimpleProductTile: () => import('../../node_modules/@osp/design-system/components/SimpleProductTile/SimpleProductTile.vue' /* webpackChunkName: "components/simple-product-tile" */).then(c => wrapFunctional(c.default || c)),
  Slider: () => import('../../node_modules/@osp/design-system/components/Slider/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c)),
  Specials: () => import('../../node_modules/@osp/design-system/components/Specials/Specials.vue' /* webpackChunkName: "components/specials" */).then(c => wrapFunctional(c.default || c)),
  StandardDropdown: () => import('../../node_modules/@osp/design-system/components/StandardDropdown/StandardDropdown.vue' /* webpackChunkName: "components/standard-dropdown" */).then(c => wrapFunctional(c.default || c)),
  StocardButton: () => import('../../node_modules/@osp/design-system/components/StocardButton/StocardButton.vue' /* webpackChunkName: "components/stocard-button" */).then(c => wrapFunctional(c.default || c)),
  Storelocator: () => import('../../node_modules/@osp/design-system/components/Storelocator/Storelocator.vue' /* webpackChunkName: "components/storelocator" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorDropdown: () => import('../../node_modules/@osp/design-system/components/StorelocatorDropdown/StorelocatorDropdown.vue' /* webpackChunkName: "components/storelocator-dropdown" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorFilterCard: () => import('../../node_modules/@osp/design-system/components/StorelocatorFilterCard/StorelocatorFilterCard.vue' /* webpackChunkName: "components/storelocator-filter-card" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorFilterDropdown: () => import('../../node_modules/@osp/design-system/components/StorelocatorFilterDropdown/StorelocatorFilterDropdown.vue' /* webpackChunkName: "components/storelocator-filter-dropdown" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreAddress: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreAddress/StorelocatorStoreAddress.vue' /* webpackChunkName: "components/storelocator-store-address" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreCard: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreCard/StorelocatorStoreCard.vue' /* webpackChunkName: "components/storelocator-store-card" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreCardMobile: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreCardMobile/StorelocatorStoreCardMobile.vue' /* webpackChunkName: "components/storelocator-store-card-mobile" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreGallery: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreGallery/StorelocatorStoreGallery.vue' /* webpackChunkName: "components/storelocator-store-gallery" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreMap: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreMap/StorelocatorStoreMap.vue' /* webpackChunkName: "components/storelocator-store-map" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreNearby: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreNearby/StorelocatorStoreNearby.vue' /* webpackChunkName: "components/storelocator-store-nearby" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreOpeningHours: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreOpeningHours/StorelocatorStoreOpeningHours.vue' /* webpackChunkName: "components/storelocator-store-opening-hours" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreSearch: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreSearch/StorelocatorStoreSearch.vue' /* webpackChunkName: "components/storelocator-store-search" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreServicesList: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreServicesList/StorelocatorStoreServicesList.vue' /* webpackChunkName: "components/storelocator-store-services-list" */).then(c => wrapFunctional(c.default || c)),
  StoresCta: () => import('../../node_modules/@osp/design-system/components/StoresCta/StoresCta.vue' /* webpackChunkName: "components/stores-cta" */).then(c => wrapFunctional(c.default || c)),
  TabToggles: () => import('../../node_modules/@osp/design-system/components/TabToggles/TabToggles.vue' /* webpackChunkName: "components/tab-toggles" */).then(c => wrapFunctional(c.default || c)),
  Tabs: () => import('../../node_modules/@osp/design-system/components/Tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c)),
  Tag: () => import('../../node_modules/@osp/design-system/components/Tag/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c)),
  Testimonial: () => import('../../node_modules/@osp/design-system/components/Testimonial/Testimonial.vue' /* webpackChunkName: "components/testimonial" */).then(c => wrapFunctional(c.default || c)),
  TextField: () => import('../../node_modules/@osp/design-system/components/TextField/TextField.vue' /* webpackChunkName: "components/text-field" */).then(c => wrapFunctional(c.default || c)),
  ThreadTaskWrapper: () => import('../../node_modules/@osp/design-system/components/ThreadTaskWrapper/ThreadTaskWrapper.vue' /* webpackChunkName: "components/thread-task-wrapper" */).then(c => wrapFunctional(c.default || c)),
  Togglebox: () => import('../../node_modules/@osp/design-system/components/Togglebox/Togglebox.vue' /* webpackChunkName: "components/togglebox" */).then(c => wrapFunctional(c.default || c)),
  TooltipBox: () => import('../../node_modules/@osp/design-system/components/TooltipBox/TooltipBox.vue' /* webpackChunkName: "components/tooltip-box" */).then(c => wrapFunctional(c.default || c)),
  Trigger: () => import('../../node_modules/@osp/design-system/components/Trigger/Trigger.vue' /* webpackChunkName: "components/trigger" */).then(c => wrapFunctional(c.default || c)),
  Trusties: () => import('../../node_modules/@osp/design-system/components/Trusties/Trusties.vue' /* webpackChunkName: "components/trusties" */).then(c => wrapFunctional(c.default || c)),
  TwoColumnsBox: () => import('../../node_modules/@osp/design-system/components/TwoColumnsBox/TwoColumnsBox.vue' /* webpackChunkName: "components/two-columns-box" */).then(c => wrapFunctional(c.default || c)),
  UspBar: () => import('../../node_modules/@osp/design-system/components/UspBar/UspBar.vue' /* webpackChunkName: "components/usp-bar" */).then(c => wrapFunctional(c.default || c)),
  UtilityNav: () => import('../../node_modules/@osp/design-system/components/UtilityNav/UtilityNav.vue' /* webpackChunkName: "components/utility-nav" */).then(c => wrapFunctional(c.default || c)),
  Video: () => import('../../node_modules/@osp/design-system/components/Video/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c)),
  VoucherTile: () => import('../../node_modules/@osp/design-system/components/VoucherTile/VoucherTile.vue' /* webpackChunkName: "components/voucher-tile" */).then(c => wrapFunctional(c.default || c)),
  WysiwygContentParser: () => import('../../node_modules/@osp/design-system/components/WysiwygContentParser/WysiwygContentParser.vue' /* webpackChunkName: "components/wysiwyg-content-parser" */).then(c => wrapFunctional(c.default || c)),
  ZoomableMedia: () => import('../../node_modules/@osp/design-system/components/ZoomableMedia/ZoomableMedia.vue' /* webpackChunkName: "components/zoomable-media" */).then(c => wrapFunctional(c.default || c)),
  BonusTipTrigger: () => import('../../node_modules/@osp/design-system/components/BonusTip/partials/BonusTip__Trigger.vue' /* webpackChunkName: "components/bonus-tip-trigger" */).then(c => wrapFunctional(c.default || c)),
  BurgerListButton: () => import('../../node_modules/@osp/design-system/components/BurgerList/partials/BurgerList__Button.vue' /* webpackChunkName: "components/burger-list-button" */).then(c => wrapFunctional(c.default || c)),
  BurgerListList: () => import('../../node_modules/@osp/design-system/components/BurgerList/partials/BurgerList__List.vue' /* webpackChunkName: "components/burger-list-list" */).then(c => wrapFunctional(c.default || c)),
  BurgerListSlider: () => import('../../node_modules/@osp/design-system/components/BurgerList/partials/BurgerList__Slider.vue' /* webpackChunkName: "components/burger-list-slider" */).then(c => wrapFunctional(c.default || c)),
  BurgerListSliderItem: () => import('../../node_modules/@osp/design-system/components/BurgerList/partials/BurgerList__SliderItem.vue' /* webpackChunkName: "components/burger-list-slider-item" */).then(c => wrapFunctional(c.default || c)),
  CategoryListItem: () => import('../../node_modules/@osp/design-system/components/CategoryList/partials/CategoryList__Item.vue' /* webpackChunkName: "components/category-list-item" */).then(c => wrapFunctional(c.default || c)),
  ColorSwitcherHeading: () => import('../../node_modules/@osp/design-system/components/ColorSwitcher/partials/ColorSwitcher__Heading.vue' /* webpackChunkName: "components/color-switcher-heading" */).then(c => wrapFunctional(c.default || c)),
  ColorSwitcherItem: () => import('../../node_modules/@osp/design-system/components/ColorSwitcher/partials/ColorSwitcher__Item.vue' /* webpackChunkName: "components/color-switcher-item" */).then(c => wrapFunctional(c.default || c)),
  ColorSwitcherList: () => import('../../node_modules/@osp/design-system/components/ColorSwitcher/partials/ColorSwitcher__List.vue' /* webpackChunkName: "components/color-switcher-list" */).then(c => wrapFunctional(c.default || c)),
  ContentNavigationDropdown: () => import('../../node_modules/@osp/design-system/components/ContentNavigation/partials/ContentNavigation__Dropdown.vue' /* webpackChunkName: "components/content-navigation-dropdown" */).then(c => wrapFunctional(c.default || c)),
  ContentNavigationList: () => import('../../node_modules/@osp/design-system/components/ContentNavigation/partials/ContentNavigation__List.vue' /* webpackChunkName: "components/content-navigation-list" */).then(c => wrapFunctional(c.default || c)),
  ContentNavigationTrigger: () => import('../../node_modules/@osp/design-system/components/ContentNavigation/partials/ContentNavigation__Trigger.vue' /* webpackChunkName: "components/content-navigation-trigger" */).then(c => wrapFunctional(c.default || c)),
  FilterMenuColorList: () => import('../../node_modules/@osp/design-system/components/FilterMenu/partials/FilterMenu__ColorList.vue' /* webpackChunkName: "components/filter-menu-color-list" */).then(c => wrapFunctional(c.default || c)),
  FilterMenuExpandable: () => import('../../node_modules/@osp/design-system/components/FilterMenu/partials/FilterMenu__Expandable.vue' /* webpackChunkName: "components/filter-menu-expandable" */).then(c => wrapFunctional(c.default || c)),
  FilterMenuTags: () => import('../../node_modules/@osp/design-system/components/FilterMenu/partials/FilterMenu__Tags.vue' /* webpackChunkName: "components/filter-menu-tags" */).then(c => wrapFunctional(c.default || c)),
  FilterMenuTitle: () => import('../../node_modules/@osp/design-system/components/FilterMenu/partials/FilterMenu__Title.vue' /* webpackChunkName: "components/filter-menu-title" */).then(c => wrapFunctional(c.default || c)),
  FilterPriceHandle: () => import('../../node_modules/@osp/design-system/components/FilterPrice/partials/FilterPrice__Handle.vue' /* webpackChunkName: "components/filter-price-handle" */).then(c => wrapFunctional(c.default || c)),
  FilterPriceInput: () => import('../../node_modules/@osp/design-system/components/FilterPrice/partials/FilterPrice__Input.vue' /* webpackChunkName: "components/filter-price-input" */).then(c => wrapFunctional(c.default || c)),
  FlyoutShade: () => import('../../node_modules/@osp/design-system/components/Flyout/partials/Flyout__Shade.vue' /* webpackChunkName: "components/flyout-shade" */).then(c => wrapFunctional(c.default || c)),
  FlyoutBrandsButton: () => import('../../node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__Button.vue' /* webpackChunkName: "components/flyout-brands-button" */).then(c => wrapFunctional(c.default || c)),
  FlyoutBrandsFeatured: () => import('../../node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__Featured.vue' /* webpackChunkName: "components/flyout-brands-featured" */).then(c => wrapFunctional(c.default || c)),
  FlyoutBrandsFilter: () => import('../../node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__Filter.vue' /* webpackChunkName: "components/flyout-brands-filter" */).then(c => wrapFunctional(c.default || c)),
  FlyoutBrandsFilters: () => import('../../node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__Filters.vue' /* webpackChunkName: "components/flyout-brands-filters" */).then(c => wrapFunctional(c.default || c)),
  FlyoutBrandsList: () => import('../../node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__List.vue' /* webpackChunkName: "components/flyout-brands-list" */).then(c => wrapFunctional(c.default || c)),
  FlyoutImagesCard: () => import('../../node_modules/@osp/design-system/components/FlyoutImages/partials/FlyoutImages__Card.vue' /* webpackChunkName: "components/flyout-images-card" */).then(c => wrapFunctional(c.default || c)),
  FlyoutImagetitleCard: () => import('../../node_modules/@osp/design-system/components/FlyoutImagetitle/partials/FlyoutImagetitle__Card.vue' /* webpackChunkName: "components/flyout-imagetitle-card" */).then(c => wrapFunctional(c.default || c)),
  FooterInfoAwards: () => import('../../node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Awards.vue' /* webpackChunkName: "components/footer-info-awards" */).then(c => wrapFunctional(c.default || c)),
  FooterInfoCopyright: () => import('../../node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Copyright.vue' /* webpackChunkName: "components/footer-info-copyright" */).then(c => wrapFunctional(c.default || c)),
  FooterInfoPayments: () => import('../../node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Payments.vue' /* webpackChunkName: "components/footer-info-payments" */).then(c => wrapFunctional(c.default || c)),
  FooterInfoServices: () => import('../../node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Services.vue' /* webpackChunkName: "components/footer-info-services" */).then(c => wrapFunctional(c.default || c)),
  FooterInfoSocials: () => import('../../node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Socials.vue' /* webpackChunkName: "components/footer-info-socials" */).then(c => wrapFunctional(c.default || c)),
  FooterUspItem: () => import('../../node_modules/@osp/design-system/components/FooterUsp/partials/FooterUsp__Item.vue' /* webpackChunkName: "components/footer-usp-item" */).then(c => wrapFunctional(c.default || c)),
  HeaderMobileButtonClose: () => import('../../node_modules/@osp/design-system/components/HeaderMobile/partials/HeaderMobile__ButtonClose.vue' /* webpackChunkName: "components/header-mobile-button-close" */).then(c => wrapFunctional(c.default || c)),
  HeaderMobileButtonOpen: () => import('../../node_modules/@osp/design-system/components/HeaderMobile/partials/HeaderMobile__ButtonOpen.vue' /* webpackChunkName: "components/header-mobile-button-open" */).then(c => wrapFunctional(c.default || c)),
  HeaderMobileButtonSearch: () => import('../../node_modules/@osp/design-system/components/HeaderMobile/partials/HeaderMobile__ButtonSearch.vue' /* webpackChunkName: "components/header-mobile-button-search" */).then(c => wrapFunctional(c.default || c)),
  InputListLabel: () => import('../../node_modules/@osp/design-system/components/InputList/partials/InputList__Label.vue' /* webpackChunkName: "components/input-list-label" */).then(c => wrapFunctional(c.default || c)),
  JerseyPrintModalTabHeading: () => import('../../node_modules/@osp/design-system/components/JerseyPrintModal/partial/JerseyPrintModal__TabHeading.vue' /* webpackChunkName: "components/jersey-print-modal-tab-heading" */).then(c => wrapFunctional(c.default || c)),
  MyAccountOrderProgressBarStep: () => import('../../node_modules/@osp/design-system/components/MyAccountOrderProgressBar/partials/MyAccountOrderProgressBar__Step.vue' /* webpackChunkName: "components/my-account-order-progress-bar-step" */).then(c => wrapFunctional(c.default || c)),
  MyAccountPurchasedProductsBatchInfo: () => import('../../node_modules/@osp/design-system/components/MyAccountPurchasedProducts/partials/MyAccountPurchasedProducts__BatchInfo.vue' /* webpackChunkName: "components/my-account-purchased-products-batch-info" */).then(c => wrapFunctional(c.default || c)),
  MyAccountPurchasedProductsConsignment: () => import('../../node_modules/@osp/design-system/components/MyAccountPurchasedProducts/partials/MyAccountPurchasedProducts__Consignment.vue' /* webpackChunkName: "components/my-account-purchased-products-consignment" */).then(c => wrapFunctional(c.default || c)),
  MyAccountPurchasedProductsDeliveryInfo: () => import('../../node_modules/@osp/design-system/components/MyAccountPurchasedProducts/partials/MyAccountPurchasedProducts__DeliveryInfo.vue' /* webpackChunkName: "components/my-account-purchased-products-delivery-info" */).then(c => wrapFunctional(c.default || c)),
  MyAccountPurchasedProductsHeading: () => import('../../node_modules/@osp/design-system/components/MyAccountPurchasedProducts/partials/MyAccountPurchasedProducts__Heading.vue' /* webpackChunkName: "components/my-account-purchased-products-heading" */).then(c => wrapFunctional(c.default || c)),
  MyAccountPurchasedProductsSubheading: () => import('../../node_modules/@osp/design-system/components/MyAccountPurchasedProducts/partials/MyAccountPurchasedProducts__Subheading.vue' /* webpackChunkName: "components/my-account-purchased-products-subheading" */).then(c => wrapFunctional(c.default || c)),
  PosAvailabilityCard: () => import('../../node_modules/@osp/design-system/components/PosAvailabilityCheck/partials/PosAvailabilityCard.vue' /* webpackChunkName: "components/pos-availability-card" */).then(c => wrapFunctional(c.default || c)),
  ProductImageGridContent: () => import('../../node_modules/@osp/design-system/components/ProductImageGrid/partials/ProductImageGrid__Content.vue' /* webpackChunkName: "components/product-image-grid-content" */).then(c => wrapFunctional(c.default || c)),
  ProductImageGridItem: () => import('../../node_modules/@osp/design-system/components/ProductImageGrid/partials/ProductImageGrid__Item.vue' /* webpackChunkName: "components/product-image-grid-item" */).then(c => wrapFunctional(c.default || c)),
  ProductImageGridSlider: () => import('../../node_modules/@osp/design-system/components/ProductImageGrid/partials/ProductImageGrid__Slider.vue' /* webpackChunkName: "components/product-image-grid-slider" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewsItem: () => import('../../node_modules/@osp/design-system/components/ProductReviews/partials/ProductReviews__Item.vue' /* webpackChunkName: "components/product-reviews-item" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewsList: () => import('../../node_modules/@osp/design-system/components/ProductReviews/partials/ProductReviews__List.vue' /* webpackChunkName: "components/product-reviews-list" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewsScore: () => import('../../node_modules/@osp/design-system/components/ProductReviews/partials/ProductReviews__Score.vue' /* webpackChunkName: "components/product-reviews-score" */).then(c => wrapFunctional(c.default || c)),
  ProductSizingInStockButton: () => import('../../node_modules/@osp/design-system/components/ProductSizing/partials/ProductSizing__InStockButton.vue' /* webpackChunkName: "components/product-sizing-in-stock-button" */).then(c => wrapFunctional(c.default || c)),
  ProductSizingOutOfStockRow: () => import('../../node_modules/@osp/design-system/components/ProductSizing/partials/ProductSizing__OutOfStockRow.vue' /* webpackChunkName: "components/product-sizing-out-of-stock-row" */).then(c => wrapFunctional(c.default || c)),
  ProductTileFooter: () => import('../../node_modules/@osp/design-system/components/ProductTile/partials/ProductTile__Footer.vue' /* webpackChunkName: "components/product-tile-footer" */).then(c => wrapFunctional(c.default || c)),
  ProductTilePrice: () => import('../../node_modules/@osp/design-system/components/ProductTile/partials/ProductTile__Price.vue' /* webpackChunkName: "components/product-tile-price" */).then(c => wrapFunctional(c.default || c)),
  SearchButton: () => import('../../node_modules/@osp/design-system/components/Search/partials/Search__Button.vue' /* webpackChunkName: "components/search-button" */).then(c => wrapFunctional(c.default || c)),
  SearchClear: () => import('../../node_modules/@osp/design-system/components/Search/partials/Search__Clear.vue' /* webpackChunkName: "components/search-clear" */).then(c => wrapFunctional(c.default || c)),
  SearchClose: () => import('../../node_modules/@osp/design-system/components/Search/partials/Search__Close.vue' /* webpackChunkName: "components/search-close" */).then(c => wrapFunctional(c.default || c)),
  SearchInput: () => import('../../node_modules/@osp/design-system/components/Search/partials/Search__Input.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c)),
  SearchItem: () => import('../../node_modules/@osp/design-system/components/Search/partials/Search__Item.vue' /* webpackChunkName: "components/search-item" */).then(c => wrapFunctional(c.default || c)),
  SideNavigationBranch: () => import('../../node_modules/@osp/design-system/components/SideNavigation/partials/SideNavigation__Branch.vue' /* webpackChunkName: "components/side-navigation-branch" */).then(c => wrapFunctional(c.default || c)),
  SliderArrow: () => import('../../node_modules/@osp/design-system/components/Slider/partials/Slider__Arrow.vue' /* webpackChunkName: "components/slider-arrow" */).then(c => wrapFunctional(c.default || c)),
  SliderArrows: () => import('../../node_modules/@osp/design-system/components/Slider/partials/Slider__Arrows.vue' /* webpackChunkName: "components/slider-arrows" */).then(c => wrapFunctional(c.default || c)),
  SliderCounter: () => import('../../node_modules/@osp/design-system/components/Slider/partials/Slider__Counter.vue' /* webpackChunkName: "components/slider-counter" */).then(c => wrapFunctional(c.default || c)),
  SliderItem: () => import('../../node_modules/@osp/design-system/components/Slider/partials/Slider__Item.vue' /* webpackChunkName: "components/slider-item" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorDesktop: () => import('../../node_modules/@osp/design-system/components/Storelocator/partials/StorelocatorDesktop.vue' /* webpackChunkName: "components/storelocator-desktop" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorMobile: () => import('../../node_modules/@osp/design-system/components/Storelocator/partials/StorelocatorMobile.vue' /* webpackChunkName: "components/storelocator-mobile" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreAddressItem: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreAddress/partials/StorelocatorStoreAddress__Item.vue' /* webpackChunkName: "components/storelocator-store-address-item" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorStoreGalleryMore: () => import('../../node_modules/@osp/design-system/components/StorelocatorStoreGallery/partials/StorelocatorStoreGallery__More.vue' /* webpackChunkName: "components/storelocator-store-gallery-more" */).then(c => wrapFunctional(c.default || c)),
  TabsItem: () => import('../../node_modules/@osp/design-system/components/Tabs/partials/Tabs__Item.vue' /* webpackChunkName: "components/tabs-item" */).then(c => wrapFunctional(c.default || c)),
  TrustiesItem: () => import('../../node_modules/@osp/design-system/components/Trusties/partials/Trusties__Item.vue' /* webpackChunkName: "components/trusties-item" */).then(c => wrapFunctional(c.default || c)),
  UspBarItem: () => import('../../node_modules/@osp/design-system/components/UspBar/patrials/UspBar__Item.vue' /* webpackChunkName: "components/usp-bar-item" */).then(c => wrapFunctional(c.default || c)),
  VideoIframe: () => import('../../node_modules/@osp/design-system/components/Video/partials/Video__Iframe.vue' /* webpackChunkName: "components/video-iframe" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../components/footer/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  RichSnippet: () => import('../../components/atoms/rich-snippet.tsx' /* webpackChunkName: "components/rich-snippet" */).then(c => wrapFunctional(c.default || c)),
  TimeCounter: () => import('../../components/atoms/time-counter.tsx' /* webpackChunkName: "components/time-counter" */).then(c => wrapFunctional(c.default || c)),
  WithRoot: () => import('../../components/atoms/with-root.tsx' /* webpackChunkName: "components/with-root" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/header/header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  ContentSlot: () => import('../../components/molecules/content-slot.tsx' /* webpackChunkName: "components/content-slot" */).then(c => wrapFunctional(c.default || c)),
  SectionLayout: () => import('../../components/section-layout/section-layout.vue' /* webpackChunkName: "components/section-layout" */).then(c => wrapFunctional(c.default || c)),
  SectionLayoutDataList: () => import('../../components/section-layout/section-layout__data-list.vue' /* webpackChunkName: "components/section-layout-data-list" */).then(c => wrapFunctional(c.default || c)),
  MyAccountThemeWithSidebar: () => import('../../components/MyAccount/MyAccountThemeWithSidebar/MyAccountThemeWithSidebar.vue' /* webpackChunkName: "components/my-account-theme-with-sidebar" */).then(c => wrapFunctional(c.default || c)),
  MyDetailsEmail: () => import('../../components/MyAccount/MyDetailsEmail/MyDetailsEmail.vue' /* webpackChunkName: "components/my-details-email" */).then(c => wrapFunctional(c.default || c)),
  MyDetailsPassword: () => import('../../components/MyAccount/MyDetailsPassword/MyDetailsPassword.vue' /* webpackChunkName: "components/my-details-password" */).then(c => wrapFunctional(c.default || c)),
  MyDetailsPersonalData: () => import('../../components/MyAccount/MyDetailsPersonalData/MyDetailsPersonalData.vue' /* webpackChunkName: "components/my-details-personal-data" */).then(c => wrapFunctional(c.default || c)),
  MyOverviewClub: () => import('../../components/MyAccount/MyOverviewClub/MyOverviewClub.vue' /* webpackChunkName: "components/my-overview-club" */).then(c => wrapFunctional(c.default || c)),
  Dy3dProductView: () => import('../../components/dynamic-yield/dy-3d-product-view/dy-3d-product-view.vue' /* webpackChunkName: "components/dy3d-product-view" */).then(c => wrapFunctional(c.default || c)),
  DyAbWrapper: () => import('../../components/dynamic-yield/dy-ab-wrapper/dy-ab-wrapper.vue' /* webpackChunkName: "components/dy-ab-wrapper" */).then(c => wrapFunctional(c.default || c)),
  DyBrandList: () => import('../../components/dynamic-yield/dy-brand-list/dy-brand-list.vue' /* webpackChunkName: "components/dy-brand-list" */).then(c => wrapFunctional(c.default || c)),
  DyCategoryList: () => import('../../components/dynamic-yield/dy-category-list/dy-category-list.vue' /* webpackChunkName: "components/dy-category-list" */).then(c => wrapFunctional(c.default || c)),
  DyProductRecommendation: () => import('../../components/dynamic-yield/dy-product-recommendation/dy-product-recommendation.vue' /* webpackChunkName: "components/dy-product-recommendation" */).then(c => wrapFunctional(c.default || c)),
  DyPromoBar: () => import('../../components/dynamic-yield/dy-promo-bar/dy-promo-bar.vue' /* webpackChunkName: "components/dy-promo-bar" */).then(c => wrapFunctional(c.default || c)),
  ActionLink: () => import('../../components/atoms/action-link/action-link.vue' /* webpackChunkName: "components/action-link" */).then(c => wrapFunctional(c.default || c)),
  AddressEntry: () => import('../../components/atoms/address-entry/address-entry.vue' /* webpackChunkName: "components/address-entry" */).then(c => wrapFunctional(c.default || c)),
  AspectRatioContainer: () => import('../../components/atoms/aspect-ratio-container/aspect-ratio-container.vue' /* webpackChunkName: "components/aspect-ratio-container" */).then(c => wrapFunctional(c.default || c)),
  ClsSectionWrapper: () => import('../../components/atoms/cls-section-wrapper/cls-section-wrapper.vue' /* webpackChunkName: "components/cls-section-wrapper" */).then(c => wrapFunctional(c.default || c)),
  CreditCard: () => import('../../components/atoms/credit-card/credit-card.vue' /* webpackChunkName: "components/credit-card" */).then(c => wrapFunctional(c.default || c)),
  Grid: () => import('../../components/atoms/grid/grid.vue' /* webpackChunkName: "components/grid" */).then(c => wrapFunctional(c.default || c)),
  Icon: () => import('../../components/atoms/icon/icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c)),
  LoadingOverlay: () => import('../../components/atoms/loading-overlay/loading-overlay.vue' /* webpackChunkName: "components/loading-overlay" */).then(c => wrapFunctional(c.default || c)),
  QuantitySelector: () => import('../../components/atoms/quantity-selector/quantity-selector.vue' /* webpackChunkName: "components/quantity-selector" */).then(c => wrapFunctional(c.default || c)),
  RadioButton: () => import('../../components/atoms/radio-button/radio-button.vue' /* webpackChunkName: "components/radio-button" */).then(c => wrapFunctional(c.default || c)),
  Textbox: () => import('../../components/atoms/textbox/textbox.vue' /* webpackChunkName: "components/textbox" */).then(c => wrapFunctional(c.default || c)),
  AddToCartTrigger: () => import('../../components/molecules/add-to-cart-trigger/add-to-cart-trigger.vue' /* webpackChunkName: "components/add-to-cart-trigger" */).then(c => wrapFunctional(c.default || c)),
  Bar: () => import('../../components/molecules/bar/bar.vue' /* webpackChunkName: "components/bar" */).then(c => wrapFunctional(c.default || c)),
  TextData: () => import('../../components/molecules/content/text-data.vue' /* webpackChunkName: "components/text-data" */).then(c => wrapFunctional(c.default || c)),
  Group2: () => import('../../components/molecules/group-2/group-2.vue' /* webpackChunkName: "components/group2" */).then(c => wrapFunctional(c.default || c)),
  Hint: () => import('../../components/molecules/hint/hint.vue' /* webpackChunkName: "components/hint" */).then(c => wrapFunctional(c.default || c)),
  IconText: () => import('../../components/molecules/icon-text/icon-text.vue' /* webpackChunkName: "components/icon-text" */).then(c => wrapFunctional(c.default || c)),
  Labelset: () => import('../../components/molecules/labelset/labelset.vue' /* webpackChunkName: "components/labelset" */).then(c => wrapFunctional(c.default || c)),
  Layout: () => import('../../components/molecules/layout/layout.vue' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c)),
  LayoutType: () => import('../../components/molecules/layout-type/layout-type.vue' /* webpackChunkName: "components/layout-type" */).then(c => wrapFunctional(c.default || c)),
  Messagebox: () => import('../../components/molecules/messagebox/messagebox.vue' /* webpackChunkName: "components/messagebox" */).then(c => wrapFunctional(c.default || c)),
  MessageboxWrapper: () => import('../../components/molecules/messagebox-wrapper/messagebox-wrapper.vue' /* webpackChunkName: "components/messagebox-wrapper" */).then(c => wrapFunctional(c.default || c)),
  SideNavigationBar: () => import('../../components/molecules/side-navigation-bar/side-navigation-bar.vue' /* webpackChunkName: "components/side-navigation-bar" */).then(c => wrapFunctional(c.default || c)),
  TermsAndConditions: () => import('../../components/molecules/terms-and-conditions/terms-and-conditions.vue' /* webpackChunkName: "components/terms-and-conditions" */).then(c => wrapFunctional(c.default || c)),
  TouchRedirectModal: () => import('../../components/molecules/touch-redirect-modal/touch-redirect-modal.vue' /* webpackChunkName: "components/touch-redirect-modal" */).then(c => wrapFunctional(c.default || c)),
  AddToCartProgress: () => import('../../components/organisms/add-to-cart-progress/add-to-cart-progress.vue' /* webpackChunkName: "components/add-to-cart-progress" */).then(c => wrapFunctional(c.default || c)),
  AddressBook: () => import('../../components/organisms/address-book/address-book.vue' /* webpackChunkName: "components/address-book" */).then(c => wrapFunctional(c.default || c)),
  CartSummation: () => import('../../components/organisms/cart-summation/cart-summation.vue' /* webpackChunkName: "components/cart-summation" */).then(c => wrapFunctional(c.default || c)),
  OverlayLegacy: () => import('../../components/organisms/overlay-legacy/overlay-legacy.vue' /* webpackChunkName: "components/overlay-legacy" */).then(c => wrapFunctional(c.default || c)),
  ProductCard: () => import('../../components/organisms/product-card/product-card.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c)),
  BillingPay: () => import('../../components/molecules/checkout/billing-pay/billing-pay.vue' /* webpackChunkName: "components/billing-pay" */).then(c => wrapFunctional(c.default || c)),
  CreditCardSelector: () => import('../../components/molecules/checkout/credit-card-selector/credit-card-selector.vue' /* webpackChunkName: "components/credit-card-selector" */).then(c => wrapFunctional(c.default || c)),
  PaymentSelector: () => import('../../components/molecules/checkout/payment-selector/payment-selector.vue' /* webpackChunkName: "components/payment-selector" */).then(c => wrapFunctional(c.default || c)),
  ProgressBar: () => import('../../components/molecules/checkout/progress-bar/progress-bar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c)),
  SecurityBar: () => import('../../components/molecules/checkout/security-bar/security-bar.vue' /* webpackChunkName: "components/security-bar" */).then(c => wrapFunctional(c.default || c)),
  AddNumberView: () => import('../../components/molecules/club/add-number-view/add-number-view.vue' /* webpackChunkName: "components/add-number-view" */).then(c => wrapFunctional(c.default || c)),
  ConfirmationView: () => import('../../components/molecules/club/confirmation-view/confirmation-view.vue' /* webpackChunkName: "components/confirmation-view" */).then(c => wrapFunctional(c.default || c)),
  ForgottenNumberView: () => import('../../components/molecules/club/forgotten-number-view/forgotten-number-view.vue' /* webpackChunkName: "components/forgotten-number-view" */).then(c => wrapFunctional(c.default || c)),
  MatchView: () => import('../../components/molecules/club/match-view/match-view.vue' /* webpackChunkName: "components/match-view" */).then(c => wrapFunctional(c.default || c)),
  NoMatchView: () => import('../../components/molecules/club/no-match-view/no-match-view.vue' /* webpackChunkName: "components/no-match-view" */).then(c => wrapFunctional(c.default || c)),
  PosConfirmation: () => import('../../components/molecules/club/pos-confirmation/pos-confirmation.vue' /* webpackChunkName: "components/pos-confirmation" */).then(c => wrapFunctional(c.default || c)),
  PosRegister: () => import('../../components/molecules/club/pos-register/pos-register.vue' /* webpackChunkName: "components/pos-register" */).then(c => wrapFunctional(c.default || c)),
  GridLayout: () => import('../../components/molecules/content/grid-layout/grid-layout.vue' /* webpackChunkName: "components/grid-layout" */).then(c => wrapFunctional(c.default || c)),
  LinkBar: () => import('../../components/molecules/content/link-bar/link-bar.vue' /* webpackChunkName: "components/link-bar" */).then(c => wrapFunctional(c.default || c)),
  GenericFormWrap: () => import('../../components/molecules/forms/GenericFormWrap/GenericFormWrap.vue' /* webpackChunkName: "components/generic-form-wrap" */).then(c => wrapFunctional(c.default || c)),
  GuestForm: () => import('../../components/molecules/forms/guest-form/guest-form.vue' /* webpackChunkName: "components/guest-form" */).then(c => wrapFunctional(c.default || c)),
  LoginForm: () => import('../../components/molecules/forms/login-form/login-form.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c)),
  RegistrationForm: () => import('../../components/molecules/forms/registration-form/registration-form.vue' /* webpackChunkName: "components/registration-form" */).then(c => wrapFunctional(c.default || c)),
  ImageLoader: () => import('../../components/molecules/image-loader/lazy-image-loader/lazy-image-loader.vue' /* webpackChunkName: "components/lazy-image-loader" */).then(c => wrapFunctional(c.default || c)),
  Scene7ImageLoader: () => import('../../components/molecules/image-loader/scene7-image-loader/scene7-image-loader.vue' /* webpackChunkName: "components/scene7-image-loader" */).then(c => wrapFunctional(c.default || c)),
  DeliveryTime: () => import('../../components/molecules/pdp/delivery-time/delivery-time.vue' /* webpackChunkName: "components/delivery-time" */).then(c => wrapFunctional(c.default || c)),
  VoucherInput: () => import('../../components/molecules/voucher/VoucherInput/VoucherInput.vue' /* webpackChunkName: "components/voucher-input" */).then(c => wrapFunctional(c.default || c)),
  OneClickGiftCard: () => import('../../components/molecules/voucher/one-click-gift-card/one-click-gift-card.vue' /* webpackChunkName: "components/one-click-gift-card" */).then(c => wrapFunctional(c.default || c)),
  VoucherList: () => import('../../components/molecules/voucher/voucher-list/voucher-list.vue' /* webpackChunkName: "components/voucher-list" */).then(c => wrapFunctional(c.default || c)),
  CheckoutFooter: () => import('../../components/organisms/checkout/checkout-footer/checkout-footer.vue' /* webpackChunkName: "components/checkout-footer" */).then(c => wrapFunctional(c.default || c)),
  CheckoutHeader: () => import('../../components/organisms/checkout/checkout-header/checkout-header.vue' /* webpackChunkName: "components/checkout-header" */).then(c => wrapFunctional(c.default || c)),
  ClickAndCollect: () => import('../../components/organisms/order-process/click-and-collect/click-and-collect.vue' /* webpackChunkName: "components/click-and-collect" */).then(c => wrapFunctional(c.default || c)),
  DeliveryAddress: () => import('../../components/organisms/order-process/delivery-address/delivery-address.vue' /* webpackChunkName: "components/delivery-address" */).then(c => wrapFunctional(c.default || c)),
  OrderSummary: () => import('../../components/organisms/order-process/order-summary/order-summary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c)),
  SizeSelector: () => import('../../components/organisms/pdp/SizeSelector/SizeSelector.vue' /* webpackChunkName: "components/size-selector" */).then(c => wrapFunctional(c.default || c)),
  AddToCart: () => import('../../components/organisms/pdp/add-to-cart/add-to-cart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c)),
  ProductList: () => import('../../components/organisms/plp/ProductList/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c)),
  BillingPayInstallmentCondition: () => import('../../components/molecules/checkout/billing-pay/billing-pay-installment-condition/billing-pay-installment-condition.vue' /* webpackChunkName: "components/billing-pay-installment-condition" */).then(c => wrapFunctional(c.default || c)),
  ProductTeaser: () => import('../../components/molecules/content/teaser/product-teaser/product-teaser.vue' /* webpackChunkName: "components/product-teaser" */).then(c => wrapFunctional(c.default || c)),
  TextPicture: () => import('../../components/molecules/content/teaser/text-picture/text-picture.tsx' /* webpackChunkName: "components/text-picture" */).then(c => wrapFunctional(c.default || c)),
  ProductTileWrap: () => import('../../components/organisms/plp/ProductList/ProductTileWrap/ProductTileWrap.vue' /* webpackChunkName: "components/product-tile-wrap" */).then(c => wrapFunctional(c.default || c)),
  TeaserContentOverlay: () => import('../../components/molecules/content/teaser/_slots/teaser-content-overlay/teaser-content-overlay.vue' /* webpackChunkName: "components/teaser-content-overlay" */).then(c => wrapFunctional(c.default || c)),
  TeaserDisclaimer: () => import('../../components/molecules/content/teaser/_slots/teaser-disclaimer/teaser-disclaimer.vue' /* webpackChunkName: "components/teaser-disclaimer" */).then(c => wrapFunctional(c.default || c)),
  TeaserExpiringShortage: () => import('../../components/molecules/content/teaser/_slots/teaser-expiring-shortage/teaser-expiring-shortage.vue' /* webpackChunkName: "components/teaser-expiring-shortage" */).then(c => wrapFunctional(c.default || c)),
  TeaserHeadline: () => import('../../components/molecules/content/teaser/_slots/teaser-headline/teaser-headline.vue' /* webpackChunkName: "components/teaser-headline" */).then(c => wrapFunctional(c.default || c)),
  TeaserLogo: () => import('../../components/molecules/content/teaser/_slots/teaser-logo/teaser-logo.vue' /* webpackChunkName: "components/teaser-logo" */).then(c => wrapFunctional(c.default || c)),
  TeaserParagraph: () => import('../../components/molecules/content/teaser/_slots/teaser-paragraph/teaser-paragraph.vue' /* webpackChunkName: "components/teaser-paragraph" */).then(c => wrapFunctional(c.default || c)),
  TeaserPreTitle: () => import('../../components/molecules/content/teaser/_slots/teaser-pre-title/teaser-pre-title.vue' /* webpackChunkName: "components/teaser-pre-title" */).then(c => wrapFunctional(c.default || c)),
  TextPictureFreeText: () => import('../../components/molecules/content/teaser/text-picture/text-picture-free-text/text-picture-free-text.vue' /* webpackChunkName: "components/text-picture-free-text" */).then(c => wrapFunctional(c.default || c)),
  TextPictureHeadline: () => import('../../components/molecules/content/teaser/text-picture/text-picture-headline/text-picture-headline.vue' /* webpackChunkName: "components/text-picture-headline" */).then(c => wrapFunctional(c.default || c)),
  CheckoutStep: () => import('../../layouts/checkout-step/checkout-step.vue' /* webpackChunkName: "components/checkout-step" */).then(c => wrapFunctional(c.default || c)),
  CheckoutTemplate: () => import('../../layouts/checkout-template/checkout-template.vue' /* webpackChunkName: "components/checkout-template" */).then(c => wrapFunctional(c.default || c)),
  ClubTemplate: () => import('../../layouts/club-template/club-template.vue' /* webpackChunkName: "components/club-template" */).then(c => wrapFunctional(c.default || c)),
  DefaultTemplate: () => import('../../layouts/default-template/default-template.vue' /* webpackChunkName: "components/default-template" */).then(c => wrapFunctional(c.default || c)),
  DefaultRoute: () => import('../../layouts/default-route/default-route.vue' /* webpackChunkName: "components/default-route" */).then(c => wrapFunctional(c.default || c)),
  MyAccount: () => import('../../layouts/my-account/my-account.vue' /* webpackChunkName: "components/my-account" */).then(c => wrapFunctional(c.default || c)),
  StorelocatorTemplate: () => import('../../layouts/storelocator-template/storelocator-template.vue' /* webpackChunkName: "components/storelocator-template" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
